.formFieldFormQuiz{
    width: 80%;
    padding: 0px 100px;
}
.inputFieldFormQuiz{
    width: 450px;
    border: 1px solid #D0D5DD;
    border-radius: 5px;
    padding: 18px 16px;
    margin-top: 5px;
    margin-bottom: 20px;
}
.topicFormQuiz{
    font-size: 24px;
    text-align: left;
    font-weight: 600;
    /* margin-top: 30px; */
    margin-bottom: 10px;
    padding: 0px 50px;
}
.bodyTextHolderFormQuiz{
    /* margin-top: 34px !important; */
    max-width: 80%;
    margin: auto;
}
.apexcharts-legend{
    flex-direction: column;
}

@media (max-width: 900px){
    .bodyTextHolderFormQuiz{
        /* margin-top: 34px !important; */
        max-width: 100%;
        margin: 0;
    }
    .formFieldFormQuiz{
        padding: 0 10px;
    }
    .inputFieldFormQuiz{
        width: 300px;
    }
}
@media (max-width: 500px){
    .inputFieldFormQuiz{
        width: 100%;
    }
}