.imageHolderLogoGetStarted{
    display: none;
    justify-content: center;
    align-items: center;
}
.logoImgGetStarted {
    /* display: none; */
    width: 104px;
    height: 36px;
    margin-top: 60px;
  }
  .selectHolderGetStarted{
    display: flex;
    justify-content: space-between;
  }
  .selectDivPickGetStarted{
    width: 47%;
  }

  @media (max-width : 900px){
    .imageHolderLogoGetStarted{
        display: block;
        margin-bottom: 10px;
        text-align: center;
    }
    .getStartedTopic{
      text-align: center;
    }
    .adTextOccupation{
      text-align: center;
    }
  }