.footerDiv{
    background-color: #F7F9FC;
    padding: 56px 30px;
    border-bottom: 1px solid rgb(208, 212, 221);
}
.footerSubPagesView{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.footerSubPageText{
    color: #0d5dba;
    text-decoration: none;
    font-family: sans-serif;
    margin: 0px 20px;
}
.rightBorderFooter{
    border-right: 1px solid rgb(208, 212, 221);
}
.footerSecondSection{
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.imgLogoIquire{
    width: 70px;
    height: 24px;
}
.subTextLogo{
    color: rgb(71, 82, 103);
    font-family: sans-serif;
    margin-top: 10px;
    font-size: 14px;
}
.emailIquireSubtext{
    font-size: 14px;
    font-weight: bold;
    margin-top: 30px;
    font-family: sans-serif;
}
.loginButtonFooter {
/* width: 100%; */
border: none;
/* background-color: #0d5eba; */
background: linear-gradient(to right, #0CAF77 0%, #3E87D7 100%);
color: white;
padding: 14px 20px;
margin-top: 24px;
border-radius: 8px;
font-size: 14px;
font-family: sans-serif;
}
.createAccountIquire{
    border: 1px solid rgb(13, 93, 186);
    color: rgb(13, 93, 186);
    border-radius: 8px;
    padding: 14px 20px;
    font-family: sans-serif;
    font-size: 14px;
}
.secondFooterDiv{
    background-color: #F7F9FC;
    padding: 15px 30px 56px;
    display: flex;
    justify-content: space-between;
}
.copyRightText{
    color: #344054;
    font-weight: 500;
    font-family: sans-serif;
}
.linkedInLogo{
    width: 25px;
    height: 25px;
    margin-left: 20px;
}
.imgLogoAll{
    display: flex;
}
.followAndImgAll{
    display: flex;
    align-items: center;
}

@media (max-width: 900px){
    .footerSubPagesView{
        justify-content: space-around;
    }
    .footerSubPageText{
        padding: 5px 0;
    }
    .buttonsFooterView{
        display: flex;
        flex-direction: column;
    }
    .loginButton{
        margin-bottom: 10px;
    }
    .secondFooterDiv{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
@media (max-width: 500px){
    .followAndImgAll{
        flex-direction: column;
        margin-top: 10px;
    }
    .footerSecondSection{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}