.loginConsultantAll{
    background-color: #E2EFFC;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.imgLogoCvReview{
    width: 93px;
    height: 32px;
    margin-bottom: 52px;
}
.loginOverViewImgHidden{
    position: relative;
}
.loginViewInputLabelText{
    background-color: white;
    padding: 40px 30px;
    border-radius: 10px;
    z-index: 100;
    position: relative;
}
.textSubLabelHeader{
    color: #645D5D;
    font-size: 14px;
    text-align: center;
    margin-bottom: 16px;
    margin-top: 8px;
}
.inputLabelCvReviewLogin{
    font-size: 14px;
    margin-top: 16px;
}
.inputTextCvReviewLogin{
    color: black;
    padding: 16px 16px;
    border: 1px solid #D0D5DD;
    width: 400px;
    border-radius: 5px;
    margin-top: 5px;
    font-size: 16px;
    outline: none;
}
.passwordDivHolderCvReview{
    padding: 18px 16px;
    border: 1px solid #D0D5DD;
    width: 400px;
    border-radius: 5px;
    margin-top: 5px;
    display: flex;
}
.passwordinputCvReview{
    border: none;
    color: black;
    display: flex;
    flex: 1;
    width: 100%;
    font-size: 16px;
    outline: none;
}
.forgotPasswordCvReview{
    color: #0D5EBA;
    font-size: 14px;
    margin-top: 5px;
}
.userIconCvReview{
    position: absolute;
    top: 60px;
    left: -95px;
    width: 230px;
    height: 230px;
    z-index: 10;
}
.userGroupCvReview{
    position: absolute;
    top: 150px;
    right: -76px;
    width: 230px;
    height: 230px;
    z-index: 10;
}
.backToLoginReviewCv{
    background-color: white;
    padding: 20px 32px;
    border-radius: 30px;
    margin-top: 40px;
    color: #0D5EBA;
}
.backToLoginReviewCvView{
    display: flex;
    justify-content: center;
    align-items: center;
}