.signupSelectDiv{
    border: 1px solid #D0D5DD;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 23px;
    padding-right: 23px;
    border-radius: 6px;
    width: 400px;
    margin-top: 48px;
    display: flex;
    align-items: center;
}
.signupSelectDivActive{
    border: 1px solid #0D5EBA;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 23px;
    padding-right: 23px;
    border-radius: 6px;
    width: 400px;
    margin-top: 48px;
    display: flex;
    align-items: center;
}
.capAndTextView{
    display: flex;
    flex: 1;
}
.capHolderDiv{
    background-color: #F0F2F5;
    padding: 8px;
    border-radius: 50%;
}
.capHolderDivActive{
    background-color: #B6D8FF;
    padding: 8px;
    border-radius: 50%;
}
.createaccountTextHolderDiv{
    display: flex;
    flex-direction: column;
    margin-left: 12px;
}
.createAccountSelectTopic{
    font-size: 16px;
    color: #101928;
    font-weight: 500;
}
.createAccountSelectTopic_Desc{
    font-size: 12px;
    color: #667185;
    margin-top: 6px;
}
.createAccountComingsoonText{
    background-color: #0F973D;
    color: white;
    padding: 5px;
    border-radius: 10px;
    font-size: 10px;
}
.createAccountContinueButton{
    border: none;
    color: white;
    width: 100%;
    background-color: #D0D5DD;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    border-radius: 8px;
    margin-top: 24px;
    font-weight: 600;
    font-size: 16px;
}
.createAccountContinueButtonActive{
    border: none;
    color: white;
    width: 100%;
    background-color: #0D5EBA;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    border-radius: 8px;
    margin-top: 24px;
    font-weight: 600;
    font-size: 16px;
}

.successModalRegisterButtonActive{
    border: none;
    color: white;
    width: 400px;
    background-color: #0D5EBA;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    border-radius: 8px;
    margin: auto;
    margin-top: 32px;
    font-weight: 600;
    font-size: 16px;
}

@media (max-width: 600px){
    .successModalRegisterButtonActive{
        width: 300px;
    }
    .gifRegisterSuccessmodal{
        width: 90%;
    }
}