.bodyTextResultQuizHolder{
    width: 100%;
}
.graphHoldersResultPage{
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin: auto;
}
.topicResultSummaryQuiz{
    font-size: 24px;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 10px;
}
.otherResoucesQuizresultView{
    margin: 50px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 40px;
}
.imgSizeViewResult{
    width: 500px;
    height: 400px;
}
.otherResoucesTextHolderResultPage{
    max-width: 45%;
}
.bodyHolderQuizResult{
    /* padding: 0px 40px; */
}

@media (max-width: 900px){
    .graphHoldersResultPage{
        flex-direction: column;
        width: 80%;
    }
    .otherResoucesQuizresultView{
        flex-direction: column;
    }
    .imgSizeViewResult{
        margin-top: 10px;
    }
}

@media (max-width: 600px){
    .otherResoucesTextHolderResultPage{
        max-width: 100%;
    }
    .imgSizeViewResult{
        width: 100%;
        height: 300px;
    }
}