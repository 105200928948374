
.viewQuizPage{
    padding: 69px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.welcomeText{
    font-size: 40px;
    font-weight: bold;
    text-align: center;
}
.bodyTextHolder{
    margin-top: 34px !important;
    max-width: 60%;
    margin: auto;
}
.instructionsParagraph{
    font-size: 16px;
    margin-top: 20px;
}
.instructionsHolder{
    display: flex;
    margin-left: 5px;
    margin-top: 15px;
    align-items: flex-start;
    width: 100%;
}
.textColorQuiz{
    color: #667185;
    font-size: 14px;
    margin-left: 5px;
}
.quizButton {
    /* width: 100%; */
    border: none;
    /* background-color: #0d5eba; */
    background: linear-gradient(to right, #0CAF77 0%, #3E87D7 100%);
    color: white;
    font-weight: bold;
    padding: 16px 55px;
    margin-top: 24px;
    border-radius: 8px;
    font-size: 16px;
    text-decoration: none;
  }

  @media (max-width: 600px){
    .welcomeText{
        font-size: 25px;
    }
    .bodyTextHolder{
        max-width: 80%;
    }
  }