.time-slot-picker {
    font-family: Arial, sans-serif;
    max-width: 300px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
  }
  .date {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .subtitle {
    font-size: 14px;
    color: #666;
    margin-bottom: 15px;
  }
  .time-slots {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 300px;
    overflow-y: scroll;
    margin-left: 20px;
  }
  .time-slot {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: white;
    border: 1px solid #0CAF77;
    border-radius: 5px;
    color: #0CAF77;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: left;
  }
  .time-slot.selected {
    background-color: #007bff;
    color: white;
  }
  .dot {
    width: 8px;
    height: 8px;
    background-color: #4caf50;
    border-radius: 50%;
    margin-right: 10px;
  }
  .scheduleUiUploadCv{
    display:flex;
    justify-content: space-around;
  }

  @media(max-width: 600px){
    .scheduleUiUploadCv{
      flex-direction: column;
      width: 90%;
      margin: auto;
    }
    .time-slots{
      margin-left: 0;
    }
    .time-slot{
      margin-top: 10px;
    }
    .react-calendar{
      width: 100%;
      margin: auto;
    }
  }