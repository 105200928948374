.pricingOptionsAllDiv{
    display: flex;
}
.pricingUploadColumnView{
    border: 1px solid #E4E7EC;
    border-radius: 10px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.transparentPricingUploadCv{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 25px;
}
.divTopicPricingUpload{
    color: #034592;
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
}
.imgMarkTextPricingUploadCv{
    display: flex;
    margin-top: 10px;
    align-items: center;
}
.transparentSecPricingUploadCv{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 25px;
}
.pricingSecUploadColumnView{
    border: 1px solid #E4E7EC;
    border-radius: 10px;
    margin-top: 30px;
    margin-left: 20px;
}

@media(max-width: 700px){
    .pricingOptionsAllDiv{
        flex-direction: column;
    }
    .pricingSecUploadColumnView{
        margin-left: 0;
    }
}