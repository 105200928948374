.rootOverallDiv{
    display: flex;
    flex-direction: row;
}
.navbarView{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 10px;
    box-shadow: -10px 0px 10px 5px #aaaaaa;
    min-height: 100vh;
    min-width: 200px;
    z-index: 100;
}
.navlinkDashboardConsultant{
    margin-top: 10px;
    display: flex;
    padding: 10px 15px;
    color: #101828;
    text-decoration: none;
    border-radius: 5px;
}
.navlinkDashboardConsultant.active {
    background-color: #E3EFFC;
    color: "#1671D9";
  }
  .searchOveralViewCvConsultant{
    background-color: white;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
  }
  .bodyBackgroundWhole{
    background-color: #F9FAFB;
    display: flex;
    flex: 1;
    min-height: 90vh;
    padding: 15px 20px;
    flex-direction: column;
  }
  .searchDivHolder{
    background-color: #F9FAFB;
    width: 500px;
    padding: 5px 20px;
    border-radius: 5px;
    display: flex;
  }