.logoImg {
  width: 104px;
  height: 36px;
  margin-top: 60px;
  margin-left: 60px;
}
.imgLeftAdView {
  display: flex;
  justify-content: center;
  align-items: center;
}
.adHolderDiv {
  padding: 24px;
  background-color: #001633;
  border-radius: 24px;
  max-width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.reactIconDiv {
  background: linear-gradient(to right, #0CAF77 0%, #3E87D7 100%);
  padding: 10px;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 50%;
}
.AdText {
  color: white;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-top: 16px;
}
.adTextOwner {
  margin-top: 16px;
  color: white;
  font-weight: 600;
}
.adTextOccupation {
  margin-top: 8px;
  color: #a29999;
  font-size: 12px;
  margin-bottom: 20px;
}

.swiper {
  width: 100%;
  /* height: 100%; */
}

.swiper-slide {
  /* text-align: center;
    font-size: 18px; */

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet {
  background-color: #a29999 !important; /* Change this to your desired color */
}
.swiper-pagination-bullet-active {
  background-color: #0d5eba !important; /* Change this to your desired active color */
}
.inputLabelText {
  margin-top: 48px;
  font-size: 14px;
}
.inputFieldView {
  width: 400px;
}
.emailInputText {
  width:365px !important;
  border: 1px solid #d0d5dd;
  padding: 16px;
  border-radius: 5px;
  margin-top: 4px;
}
.emailSelectText {
  width:100% !important;
  border: 1px solid #d0d5dd;
  padding: 16px;
  border-radius: 5px;
  margin-top: 4px;
}
.passwordDiv {
  width: 400px;
  border: 1px solid #d0d5dd;
  padding: 16px;
  border-radius: 5px;
  margin-top: 4px;
  display: flex;
}
.passwordFieldText {
  border: none;
  flex: 1;
}
.forgotPasswordText {
  text-decoration: underline;
  color: #0d5eba;
  font-size: 14px;
  margin-top: 10px;
}
.loginButton {
  width: 100%;
  border: none;
  /* background-color: #0d5eba; */
  background: linear-gradient(to right, #0CAF77 0%, #3E87D7 100%);
  color: white;
  font-weight: bold;
  padding: 16px;
  margin-top: 24px;
  border-radius: 8px;
  font-size: 16px;
}
.signUpText {
  font-size: 14px;
  margin-top: 10px;
  color: #667185;
}
.errorLogin {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}
@media (max-width: 500px){
  .emailInputText{
    width: 280px !important;
  }
  .inputFieldView{
    width: 310px;
  }
}