.startQuizDescText{
    font-size: 14px;
    color: #667185;
}
.topicStartQuiz{
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 10px;
}
.startQuizButtonQuestionPage{
    color: white;
    border-radius: 5px;
    background: linear-gradient(to right, #0CAF77 0%, #3E87D7 100%);
    padding: 15px 70px;
    font-size: 14px;
    background-color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    border: none;
}