.topicUploadCv{
    font-size: 36px;
    color: #101928;
    font-weight: bold;
}
.subTextTopicUploadCv{
    text-align: center;
    color: #667185;
    font-size: 16px;
    margin-top: 10px;
}
.uploadViewDivCV{
    border: 1px dashed #D0D5DD;
    border-radius: 10px;
    width: 580px;
    height: 264px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    flex-direction: column;
}
.clickTextUploadCv{
    color: #475367;
    margin-top: 15px;
}
.clickBolderUploadCV{
    font-weight: bold;
    color: #0D5EBA;
}
.fileTypeUploadCV{
    color: #98A2B3;
    font-size: 14px;
}
.lineOrUploadCv{
    height: 1px;
    width: 40%;
    background-color: #F0F2F5;
}
.orDivHolderUploadCv{
    display: flex;
    margin: 20px 0;
    align-items: center;
    width: 100%;
    justify-content: space-around;
}
.browseButtonUploadCv{
    border: 1.5px solid #0D5EBA;
    background-color: white;
    color: #0D5EBA;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 10px;
}
.supportingInfoUploadCvTopic{
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    margin-top: 30px;
}
.supportTextAreaUploadCv{
    width: 580px;
    min-height: 200px;
    margin-top: 10px;
    border: 1px solid #D0D5DD;
    padding: 20px;
    border-radius: 5px;
}
.inputFielUploadCvDiv{
    display: flex;
}
.inputTypeFormUploadCvInfo{
    border: 1px solid #D0D5DD;
    padding: 13px 8px;
    border-radius: 5px;
    margin-top: 5px;
    width: 300px;
}
.divInputIndividualUploadCvInfo{
    margin-left: 20px;
    margin-top: 20px;
}

@media(max-width: 700px){
    .uploadViewDivCV{
        width: 80%;
        margin: auto;
    }
    .supportTextAreaUploadCv{
        width: 80%;
        margin: auto;
    }
    .inputFielUploadCvDiv{
        flex-direction: column;
        align-items: center;
    }
}

@media(max-width: 400px){
    .inputTypeFormUploadCvInfo{
        width: 250px;
    }
}