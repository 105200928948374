.boxDesignHolder{
    border: 1px solid #E4E7EC;
    padding: 10px 15px;
    min-width: 30%;
    background-color: white;
    border-radius: 5px;
}
th{
    font-weight: 400;
    font-size: 14px;
    padding: 10px 10px;
    text-align: left;
}
td{
    font-weight: 400;
    font-size: 14px;
    padding: 10px 10px;
    text-align: left;
}