.topicCategoryQuiz{
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 62px;
}
.categoryHolderQuiz{
    display: flex;
    justify-content: space-around;
}
.categoryDivSelectView{
    border: 1px solid #D0D5DD;
    padding: 19px 26px;
    border-radius: 5px;
}
.capDivIconView{
    padding: 10px 10px 7px;
    background-color: #F0F2F5;
    border-radius: 50%;
}
.categoryTopicSelectTextQuiz{
    font-size: 20px;
    font-weight: 500;
    margin-top: 12px;
    margin-bottom: 6px;
}
.capSelectViewHolder{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.categorySubtopicSelectTextQuiz{
    font-size: 14px;
    color: #667185;
    max-width: 200px;
    line-height: 1.35;
}
.quizCategoryTextHolder{
    margin-top: 34px !important;
    max-width: 45%;
    margin: auto;
}
.quizCategoryPageButton {
    width: 100%;
    margin: auto;
    border: none;
    /* background-color: #0d5eba; */
    background: linear-gradient(to right, #0CAF77 0%, #3E87D7 100%);
    color: white;
    padding: 18px 20px;
    margin-top: 24px;
    border-radius: 8px;
    font-size: 16px;
    font-family: sans-serif;
    margin-top: 50px;
    margin-bottom: 90px;
    font-weight: bold;
    }

    @media (max-width: 950px){
        .quizCategoryTextHolder{
            max-width: 80%;
        }
    }

    @media (max-width: 660px){
        .categoryHolderQuiz{
            /* max-width: 80%; */
            flex-direction: column;
        }
        .categoryDivSelectView{
            margin-top: 10px;
        }
    }