.questionTimeDivHolder{
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}
.questionText{
    color: #101928;
    font-size: 14px;
    font-weight: bold;
}
.stopTimeText{
    color: #101928;
    font-size: 14px;
}
.questionTesttoAnswer{
    font-size: 24px;
    font-weight: 600;
    color: #101928;
    margin-top: 37px;
    margin-bottom: 40px;
}
.horizontalLineQuestionPage{
    margin-top: 31px;
    border-bottom: 1px solid #E4E7EC;
    margin-bottom: 44px;
}
.AnswerToQuestionPage{
    display: flex;
    margin-bottom: 10px;
}
.answerOptionQuestionPage{
    margin-left: 12px;
    color: #101928;
}
.buttonsQuestionsPage{
    margin: 97px 0;
    display: flex;
    justify-content: space-between;
}
.previousButtonQuestionPage{
    color: #0D5EBA;
    border: 1px solid #0D5EBA;
    border-radius: 5px;
    padding: 8px 10px;
    font-size: 14px;
    background-color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
}
.nextButtonQuestionPage{
    color: white;
    border-radius: 5px;
    background: linear-gradient(to right, #0CAF77 0%, #3E87D7 100%);
    padding: 8px 10px;
    font-size: 14px;
    background-color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    border: none;
}

.doneQuestionPageModal{
    border: none;
    color: white;
    width: 350px;
    background: linear-gradient(to right, #0CAF77 0%, #3E87D7 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    border-radius: 8px;
    margin: auto;
    margin-top: 32px;
    font-weight: 600;
    font-size: 16px;
}